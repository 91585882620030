<template>
  <PullRefresh v-model="isLoading" @refresh="onRefresh">
    <div class="person-con">
      <div class="person-top">
        <div class="person-setting">
          <Icon name="setting" @click="gotoSetting" />
        </div>
        <div class="person-top-headimg">
          <div class="top-img">
            <img :src="information.headimgurl" alt v-if="information.headimgurl" />
            <img src="@static/forum/tou.jpg" alt v-else />
          </div>
          <div class="top-con">
            <span class="nickname">{{information.username}}</span>
            <span>邀请码：{{information.reco_code}}</span>
          </div>
        </div>
        <div class="setting-con">
          <div class="setting-child" v-for="(setChild, idx) in 4" :key="idx">
            <span>10</span>
            <span>商品收藏</span>
          </div>
        </div>
      </div>

      <div class="person-main">
        <personMobel title="我的资产" :arrObj="wallet"></personMobel>
        <personMobel
          title="我的订单"
          @click.native="goOrder"
          @toOrder="toOrder"
          rightText="全部订单"
          :arrowStatus="true"
          style="margin-top:10px"
          :arrObj="arrObj"
        ></personMobel>
        <personMobel style="margin-top:10px">
          <template>
            <div class="person-img">
              <img
                src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1574506109628&di=3fcc56092e6f5ad9e889c269f9370c95&imgtype=0&src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2017-11-20%2F5a127cbdaab83.jpg"
                alt
              />
            </div>
          </template>
        </personMobel>
        <personMobel title="我的工具" style="margin-top:10px"></personMobel>
      </div>
    </div>
  </PullRefresh>
</template>
<script>
import { PullRefresh, Icon } from "vant";
import Setting from "@api/setting";
import personMobel from "@/components/public/personMobel";
export default {
  components: {
    PullRefresh,
    Icon,
    personMobel
  },
  data() {
    return {
      isLoading: false,
      count: 0,
      active: 0,
      arrObj: {
        type: "img",
        item: [
          {
            icon: "",
            name: "待付款",
            value: 1000,
            num: 5
          },
          {
            icon: "",
            name: "待发货",
            value: 40,
            num: 9
          },
          {
            icon: "",
            name: "待收货",
            value: 6,
            num: 10
          },
          {
            name: "评价有礼",
            value: "最高5万元",
            num: 0
          },
          {
            icon: "",
            name: "退款售后",
            value: "最高5万元",
            num: 1
          }
        ]
      },
      information: {},
      wallet: {
        type: "text",
        item: [
          {
            name: "余额（元）",
            value: 1000
          },
          {
            name: "优惠券",
            value: 40
          },
          {
            name: "积分",
            value: 6
          },
          {
            name: "乐享花（元）",
            value: "最高5万元"
          }
        ]
      }
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      try {
        const res = await Setting.GetInfo();
        if (res.code == 200) {
          this.information = res.data;
          this.$set(
            this.wallet.item[0],
            "value",
            this.information.wallet.balance
          );
          this.$set(
            this.wallet.item[1],
            "value",
            this.information.wallet.balance
          );
          this.$set(this.wallet.item[2], "value", this.information.wallet.point);
        } else {
          this.$dialog.confirm({
            title: res.msg,
          }).then(() => {
            this.$router.push('/userlogin');
            // on confirm
          }).catch(() => {
            // on cancel
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    goOrder() {
      this.$router.push({
        path: "/order",
        query: {
          active: this.active
        }
      });
    },
    toOrder(idx) {
      this.active = idx;
    },
    gotoSetting() {
      this.$router.push("setting");
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.count++;
        console.log(this.count);
      }, 500);
    }
  }
};
</script>
<style lang="scss" scoped>
.person-con {
  width: 100%;
  height: 100%;
  background: rgba(244, 244, 244, 1);
  padding-bottom: 100px;
  .person-top {
    width: 100%;
    height: 200px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      0deg,
      rgba(255, 128, 0, 1) 0%,
      rgba(255, 177, 1, 1) 100%
    );
    .setting-con {
      width: 100%;
      height: 40px;
      display: flex;
      margin-top: 20px;
      div {
        flex: 1;
        display: flex;
        color: #ffffff;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }
    .person-setting {
      color: #ffffff;
      font-size: 14px;
      text-align: right;
      line-height: 30px;
    }
    .person-top-headimg {
      height: 60px;
      align-items: center;
      display: flex;
      .top-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .top-con {
        width: 60%;
        height: 50px;
        display: flex;
        padding-left: 10px;
        flex-direction: column;
        justify-content: space-between;
        span:nth-child(2) {
          color: #ffffff;
        }
      }
    }
    .nickname {
      color: #ffffff;
      font-size: 14px;
    }
  }
  .person-main {
    width: 96%;
    height: 500px;
    margin: -20px auto;
    z-index: 100;
    .person-img {
      width: 100%;
      height: 110px;
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
}
</style>
