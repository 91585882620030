<template>
  <div class="person-box">
    <slot>
      <div class="person-box-top">
        <span>{{title}}</span>
        <div class="person-box-top-right">
          <span class="box-top-title">
            {{rightText}}
          </span>
          <Icon v-if="arrowStatus" name="arrow" />
        </div>
      </div>
    </slot>
    <div class="person-box-item" v-if="arrObj.type == 'text'" >
      <div class="child" v-for="(child, idx) in arrObj.item" :key="idx">
        <span class="top-text">{{child.value}}</span>
        <span>{{child.name}}</span>
      </div>
    </div>
    <div class="person-box-item" v-if="arrObj.type == 'img'" >
      <div class="child" v-for="(child, idx) in arrObj.item" :key="idx" @click="toOrder(idx)">
        <Icon class="top-text" name="logistics"></Icon>
        <span>{{child.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from 'vant';
export default {
  props: {
    rightText: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    arrowStatus: {
      type: Boolean,
      default: false
    },
    arrObj: {
      type: Object,
      default: function() {
        let obj = {};
        obj.type = "text";
        obj.item = [
          {
            name: "余额（元）",
            value: 1000
          },
          {
            name: "优惠券",
            value: 40
          },
          {
            name: "积分",
            value: 6
          },
          {
            name: "乐享花（元）",
            value: "最高5万元"
          }
        ]
        return obj;
      }
    }
  },
  components: { Icon },
  methods: {
    toOrder(idx) {
      this.$emit("toOrder", idx + 1);
    }
  }
}
</script>
<style lang="scss" scoped>
.person-box {
  width: 100%;
  height: auto;
  border-radius: 6px;
  background:rgba(255,255,255,1);
  .person-box-top {
    width: 96%;
    height: auto;
    display: flex;
    font-size: 16px;
    color: #333333;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($color: #f4f4f4, $alpha: 1.0);
    .person-box-top-right {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .box-top-title {
      font-size: 14px;
      color: #999999;
    }
  }
  .person-box-item {
    width: 100%;
    height: 66px;
    padding-top: 4px;
    margin: 0 auto;
    display: flex;
    .child {
      flex: 1;
      padding: 10px 2px;
      display: flex;
      font-size: 14px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .top-text {
        font-size: 16px;
        color: #FF8000;
      }
    }
  }
}
</style>